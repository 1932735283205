/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.form__checkbox--invalid {
  color: #eb4b55;
}
.form__checkbox--invalid .ant-checkbox-inner {
  border-color: #eb4b55;
}
