/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.result {
  padding: 48px 32px;
  color: #404040;
  text-align: center;
}
.result__icon {
  margin-bottom: 24px;
  font-size: 4em;
}
.result__title {
  font-size: 1.5em;
}
.result__sub-title {
  color: #9f9f9f;
  font-size: 0.875em;
}
.result__extra {
  margin-top: 24px;
}
