/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.form__item {
  position: relative;
  margin-bottom: 20px;
  font-size: 13px;
}
.form__item__head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}
.form__item__head__label {
  font-weight: 600;
}
.form__item__head__error {
  color: #eb4b55;
  text-align: right;
}
