/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.simple-form-layout {
  min-height: 60vh;
  margin: 40px auto;
}
.simple-form-layout__title {
  margin-bottom: 24px;
  color: #40556b;
  font-size: 20px;
  font-weight: 600;
}
.simple-form-layout .form-section__label {
  color: #40556b;
}
.simple-form-layout .simple-form-layout__hint {
  margin-bottom: 24px;
  padding: 12px;
}
.simple-form-layout .simple-form-layout__hint .icon {
  margin-right: 10px;
}
.simple-form-layout .form__item__head {
  margin-bottom: 8px;
}
.simple-form-layout .form__item__head .form__item__head__label {
  padding-left: 8px;
  color: #40556b;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.2;
}
.simple-form-layout__item {
  margin-bottom: 16px;
}
.simple-form-layout__item__input,
.simple-form-layout__item__input > input {
  height: 40px;
  border-radius: 4px;
  border-color: #e3ebef;
  color: #40556b;
}
.simple-form-layout__item__checkbox {
  color: #6f7f90;
  font-size: 12px;
}
.simple-form-layout__item__checkbox a,
.simple-form-layout__item__checkbox .ant-checkbox-checked + span {
  font-weight: 600;
}
.simple-form-layout__item--submit {
  text-align: right;
}
.simple-form-layout__item--submit .ant-btn {
  width: 185px;
  margin-left: 16px;
}
.simple-form-layout__footer {
  padding: 12px 0;
  border-top: solid 1px #e3ebef;
  color: #40556b;
  font-size: 13px;
}
.users.users--confirmation,
.users.users--login,
.users.users--change-password,
.users.users--request-password {
  max-width: 467px;
}
.users.users--login .users__form__forget-password {
  text-align: right;
}
.users.users--login .users__form__forget-password,
.users.users--login .users__form__item__checkbox {
  padding: 0;
  font-size: 13px;
  line-height: 18px;
}
.users.users--login .users__form__forget-password .ant-checkbox,
.users.users--login .users__form__item__checkbox .ant-checkbox {
  top: 0;
}
.users.users--login .users__form__login-with-sso {
  font-size: 13px;
  text-align: right;
}
