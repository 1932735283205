// own variables:
// Font sizes with context
$font-size-xlarge: 20px;
$font-size-base: 16px;
$font-size-large: 15px;
$font-size-medium: 13px;
$font-size-small: 10px;
$base-font-size: $font-size-medium;

$filter-button-hover-background: #ededed;

$brand-color: #59c5b4;
$brand-link-color: #000;
$brand-color-semi-transparent: rgba(89, 197, 180, 0.8);

$brand-status-color-ok: #4eb976;
$brand-status-color-error: #ff856b;
$brand-status-color-unknown: #ccc;

$pro-color: #f90;
$business-color: #ffa800;
$business-color-secondary: #fff6e5;

$status-indicator-height: 0.5rem;
$status-proposed-color: #4bb8e3;
$status-proposal-declined-color: #828282;
$status-option-color: #6ed24b;
$status-waiting-color: #ffa500;
$status-denied-color: #f00;
$status-archived-color: #828282;

$flash-message-info-background-color: #dbffca;
$flash-message-warning-background-color: #fcc9a7;
$flash-message-error-background-color: #f3a29b;
$flash-message-warning-text-color: #441f07;
$flash-message-error-text-color: #2f0c09;
$flash-message-info-text-color: #277a00;

$inquiry-status-blue-color: #4bb8e3;
$inquiry-status-orange-color: #ffa500;
$inquiry-status-green-color: #6ed24b;
$inquiry-status-grey-color: #828282;
$inquiry-status-red-color: #f00;

$button-secondary-color: #dadada;
$button-primary-hover-color: #3fbfb0;
$button-secondary-hover-color: #666;
$button-close-color: #ccc;
$button-danger-bordered-border: #ed4839;
$button-danger-bordered-border-hover: #f46457;
$button-danger-bordered-color: #ea3e69;
$button-action-bordered-color: #6f7f90;
$button-action-bordered-border: #6f7f90;
$button-action-bordered-disabled: #ccd6df;

// actual Event Inc colors from homepage
$secondary-color: #bdbdbd;
$secondary-bg-color: #f7f7f7;
$disabled-bg-color: #9f9f9f;
$card-bg-color: #f8f8f8;
$transparent-bgcolor: rgba(250, 250, 250, 0.4);
$border-color: #d9d9d9;
$focus-border-color: #dadada;
$dark-border-color: #a5a5a5;
$black: #000;
$white: #fff;
$hidden-location-color: #f00;
$shadow-color: rgba(0, 0, 0, 0.5);
$light-shadow-color: rgba(0, 0, 0, 0.15);
$box-shadow-base: 0 2px 8px $light-shadow-color;
$transparent-color: rgba(0, 0, 0, 0);
$transparent-white: rgba(255, 255, 255, 0);
$default-box-shadow: 0 6px 12px $light-shadow-color;
$hover-card-box-shadow: 0 1px 3px rgba(106, 115, 125, 0.3);
$layer-background-color: rgba(0, 0, 0, 0.6);
$button-box-shadow: 2px 2px 6px 0 $light-shadow-color;
$disabled-text-color: rgba(0, 0, 0, 0.25);
$transparent-button-background: rgba(0, 0, 0, 0.35);
$transparent-spinner-background: rgba(0, 0, 0, 0.45);
$transparent-white-background: rgba(255, 255, 255, 0.5);
$card-light-text-color: #5a5a5a;

$border: 1px solid $border-color;
$default-border-radius: 7px;

$border-color-base: $border-color;
//  Header Redesign Variables

// brand colors (see https://projects.invisionapp.com/share/6DBSQ01NV#/screens)
$foundation-color: #404040;
$foundation-light-color: #9f9f9f;
$foundation-lighter-color: #ebebeb;
$peacock-color: #00aa96;
$peacock-sharp-color: #00c6b3;
$peacock-light-color: #d2ede9;
$old-gold-color: #aa8c78;
$indigo-color: #465096;

$label-color: #9b9b9b;
$expandable-label-color: #999;
$gray-button-text-color: #808080;
$separator-color: #d8d8d8;
$light-text-color: #9b9b9b;
$lighter-text-color: #e6e6e6;
$peacock-border-color: #018f81;
$notification-border-color: #99bce1;
$light-background-color: #ddd;
$button-border-light: #9b9b9b;

$consultant-note-color: #bfe9e4;
$provider-note-color: #e9eef8;

// product colors (see https://projects.invisionapp.com/share/6DBSQ01NV#/screens)
$neutral-color: #5096e1;
$error-color: #eb4b55;
$attention-color: #ff9650;
$success-color: #7dc364;
$commission-free-background-color: #fffef4;
$commission-free-color: #ffc700;

$brand-font-family: 'Montserrat', 'Arial', 'Helvetica Neue', helvetica, sans-serif;

$space-0: 8px;
$space-1: 16px;
$space-2: 20px; // $inuit-base-spacing-unit;
$space-3: 30px;
$space-4: 40px;
$space-5: 50px;
$space-6: 60px;
$space-7: 70px;

$content-max-width: 1200px;
$content-left-column-width: 700px;

$medium-image-height: 289px;

$image-aspect-ratio: 45.15625%; // 289 * 640

$site-header-height: 82px;

$layer-footer-height: 40px;
$layer-footer-padding-top: 32px;
$layer-footer-padding-x: 16px;
$layer-footer-padding-bottom: 24px;
$layer-footer-desktop-padding-x: 36px;
$layer-footer-desktop-padding-bottom: 40px;
$layer-footer-border: 1px;
$layer-footer-outerHeight: calc($layer-footer-height + $layer-footer-border + ($layer-footer-padding-top + $layer-footer-padding-bottom));
$layer-footer-outerHeight-desktop: calc($layer-footer-height + $layer-footer-border + ($layer-footer-padding-top + $layer-footer-desktop-padding-bottom));

$provider-card-min-width: 420px; // minimum width of the card

// Navy colors
$navy-text-color-1: #40556b;
$navy-text-color-2: #6f7f90;
$navy-text-color-3: #97a3af;

$navy-border: #e3ebef;

$navy-table-row-highlight: #f5f8f9;

// EventRating colors
$eventrating-background-color: #1d1d1b;
$eventrating-text-color: $white;

// Messenger colors
$consultant-message-background-color: #b9e6e0;
$customer-message-background-color: #f4f3f4;
$provider-message-background-color: #e6ebf7;

// New Projects UI colors
$projectui-background: #f9f9f9;
$projectui-border: $navy-border;
$projectui-border-dark: #92a4b9;

$projectui-open-request-status: #00b7a0;

// New provider dashboard colors
$provider-dashboard-background: $projectui-background;
$provider-dashboard-border-dark: $projectui-border-dark;
$provider-dashboard-border: $navy-border;

// Selected item colors
$selected-color-light: #e2f2f9;
$selected-color-normal: #2e7ec6;
$selected-color-sharp: #0082f9;

$switch-color: $selected-color-normal;
$accent-2-color: #0081f8;

// Panel colors
$panel-border: $projectui-border;
$panel-header-background: #6f7f90;

// Star rating
$star-not-filled: $border-color;
$star-filled: $peacock-sharp-color;

// Project status colors
$project-booked: $success-color;
$project-booked-archived: lighten($project-booked, 30%);
$project-not-booked: $button-danger-bordered-color;
$project-not-booked-archived: lighten($project-not-booked, 30%);

// Header colors
$header-text-color: #242424;

// Map
$map-icon-color: $header-text-color;
$map-box-shadow: 2px 2px 2px 0 $light-shadow-color;

// Breakpoints
$screen-xs: 480px;
$screen-sm: 600px;
$screen-md: 960px;
$screen-lg: 1280px;
$screen-xl: 1440px;
$screen-xxl: 1600px;

// ant design variables:
// possible config variables:
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.scss

$primary-color: $peacock-color;

$font-family: $brand-font-family;

$text-color: $foundation-color;
$text-color-secondary: $label-color; // used for the titles inside the dropdown
$line-height-base: 1.2;
$border-radius-base: 0;
$border-radius-small: 4px;

$link-color: $peacock-sharp-color;
$link-hover-color: $link-color;
$link-active-color: $link-color;

$outline-color: $neutral-color;

$btn-height-sm: 32px;

$label-color: #a6a6a6;

$input-height-base: 36px;
$input-height-lg: 48px;
$input-padding-horizontal: 15px;
$input-addon-bg: $white;
$input-hover-border-color: $neutral-color;
$input-border-radius: 4px;

$btn-padding-base: 7px 16px;

$checkbox-color: $selected-color-sharp;

$neutral4-color: #9b9b9b;
$navy-text3-color: #97a3af;
