@import "styles/_variables.scss";

.password-input {
  :global {
    .icon.icon-eye,
    .icon.icon-eye-slash {
      color: $navy-text-color-3;
    }
  }
}
