.hint {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding: 18px;
  border: 1px solid #d9d9d9;
  border-radius: 9px;
  color: #40556b;
  font-size: 13px;
  line-height: 1.4;
  white-space: break-spaces;
}
.hint__content {
  white-space: initial;
}
.hint__content b {
  font-weight: 600;
}
.hint .icon {
  margin: 0 20px auto 0;
  font-size: 20px;
}
.hint--error {
  border-color: #ea3e69;
  color: #ea3e69;
}
.hint--notice {
  border-color: #5096e1;
  color: #5096e1;
}
.hint--success {
  border-color: #00aa96;
  color: #00aa96;
}
.hint--attention {
  border-color: #ff9650;
  color: #ff9650;
}
.hint--navy {
  border-color: #e3ebef;
  color: #40556b;
}
.hint--navy a {
  color: #40556b;
}
.hint--bold {
  font-weight: 600;
}
