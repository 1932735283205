/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.form__input.ant-input.form__input--invalid {
  border-color: #eb4b55;
}
.form__input.ant-input:focus,
.form__input .ant-input:focus {
  border-color: #0082f9;
}
